import React from 'react';
import styled, { css } from 'styled-components';
import SimpleTag from '../../../components/reusable/SimpleTag';
import { Player } from '@lottiefiles/react-lottie-player';
import Hero_animation from '../../../lotties/bg_verification_hero_animation.json';
import Hero_animation_fallback from '../../../images/product-assets/bg-check-fallback.svg';
import { vendorLogosList } from '../../../helper/constant';
import Hero_mobile_img from '../../../images/product-assets/bg-check-mobile.svg';
import useIsPageLoaded from '../../../hooks/useIsPageLoaded';
import { useModal } from '../../../context/ModalContext';
import Vendors from '../../../components/reusable/vendors/Index';

const Container = styled.section`
    padding-top: 50px;
    background: #f3fafe;
    position: relative;
    overflow: hidden;
`;

const HeroContainer = styled.div`
    margin: 0 auto;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 800px) {
        padding-bottom: 32px;
    }
`;

const Heading = styled.h1`
    color: #262626;
    font-size: 64px;
    font-weight: 500;
    line-height: 76px;
    text-align: center;
    margin-top: 28px;
    max-width: 1280px;
    text-align: center;

    @media screen and (max-width: 800px) {
        font-size: 36px;
        line-height: 42.66px;
        margin-top: 16px;
    }

    @media screen and (max-width: 500px) {
        font-size: 24px;
        line-height: 28px;
    }
`;

const SubHeading = styled.p`
    font-size: 18px;
    font-weight: 400;
    line-height: 21.33px;
    text-align: center;
    color: #262626;
    margin-top: 28px;
    width: 50%;

    @media screen and (max-width: 800px) {
        font-size: 16px;
        line-height: 18.96px;
        width: 80%;
        margin-top: 16px;
    }

    @media screen and (max-width: 500px) {
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        width: 100%;
    }
`;

const BtnGroup = styled.div`
    margin-top: 44px;
    margin-bottom: 44px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 800px) {
        margin-bottom: 16px;
    }

    @media screen and (max-width: 500px) {
        margin-top: 32px;
        flex-direction: column;
    }
`;
const SharedCtaStyle = css`
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: #fff;
    background: #0c1a68;
    padding: 9.5px 16px;
    border-radius: 4px;
    text-decoration: none;
    border: 1px solid #0c1a68;
    cursor: pointer;

    &:hover {
        transform: scale(1.1);
        transition: all 0.2s;
    }

    @media screen and (max-width: 500px) {
        width: 200px;
        text-align: center;
    }

    ${({ secondary }) =>
        secondary &&
        css`
            background: none;
            color: #0c1a68;
            border: 1px solid #0c1a68;
        `};
`;
const CtaLink = styled.a`
    ${SharedCtaStyle}
`;
const CtaButton = styled.button`
    ${SharedCtaStyle}
    outline : none;
`;
const Header = styled.div`
    padding: 72px 20px 60px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    background: linear-gradient(180deg, #ffffff 9.46%, #f6fbfe 68.75%, #f3fafe 84.51%);

    @media screen and (max-width: 800px) {
        padding: 72px 20px 32px;
    }

    @media screen and (max-width: 500px) {
        padding: 32px 20px;
    }
`;

const AnimationContainer = styled.div`
    min-height: 500px;

    @media screen and (max-width: 600px) {
        min-height: none;
    }
`;

const SharedStyle = css`
    transform: translateY(-25%);
    width: 100%;
    height: auto;

    @media screen and (max-width: 600px) {
        display: none;
    }
`;

const AnimationPlayer = styled(Player)`
    ${SharedStyle}
`;

const FallbackImg = styled.img`
    ${SharedStyle}
`;

const MobileAnimation = styled.img`
    object-fit: cover;
    @media screen and (min-width: 600px) {
        display: none;
    }
`;

const VendorSection = styled.div`
    padding: 20px;
    max-width: 1280px;
    margin: 0 auto;
    margin-top: 28px;
    background-color: transparent;
    z-index: 1;
    border-radius: 35%;

    @media screen and (min-width: 600px) {
        margin-top: -20%;
    }
`;

const VendorHeading = styled.p`
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    color: #262626;
    text-align: center;
`;
const LogoContainer = styled.div`
    max-width: 900px;
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    column-gap: 68px;
    row-gap: 24px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    color: white;

    @media screen and (max-width: 900px) {
        row-gap: 16px;
    }

    @media screen and (max-width: 500px) {
        margin-top: 24px;
        gap: 24px;
    }
`;
const Link = styled.a`
    text-decoration: none;
    cursor: pointer;
`;
const CompanyLogo = styled.img`
    height: ${({ customSize }) => (customSize ? '14px' : '22px')};
    width: auto;

    @media screen and (max-width: 900px) {
        height: 15px;
    }

    @media screen and (max-width: 500px) {
        height: auto;
        max-height: 28px;
    }
`;

function HeroSection() {
    const { isLoaded } = useIsPageLoaded();
    const { handleOpen } = useModal();
    return (
        <Container id="heroSection">
            <HeroContainer>
                <Header>
                    <SimpleTag text="Product · Background Verifications" color="#2C66B8" />
                    <Heading>Enhanced Security with Instant Background Verifications!</Heading>
                    <SubHeading>
                        With Authenticate®’s Background Verifications, you can instantly verify a
                        user’s employment, education, professional license, and more for added trust
                        and more security.
                    </SubHeading>
                    <BtnGroup>
                        <CtaLink
                            href="https://portal.authenticate.com/register"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Sign Up
                        </CtaLink>
                        <CtaButton type="button" secondary onClick={handleOpen}>
                            Schedule a Demo
                        </CtaButton>
                    </BtnGroup>
                </Header>
                <AnimationContainer>
                    {!isLoaded ? (
                        <FallbackImg src={Hero_animation_fallback} alt="Main background" />
                    ) : (
                        <AnimationPlayer src={Hero_animation} loop autoplay />
                    )}
                    <MobileAnimation src={Hero_mobile_img} alt="mobile illustration" />
                </AnimationContainer>
                <VendorSection>
                    <Vendors effectColor="#f3fafe" />
                </VendorSection>
            </HeroContainer>
        </Container>
    );
}

export default HeroSection;
